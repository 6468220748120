import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import HistoricalTable from './component';
import {  } from '../../actions';

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({}, dispatch)};
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error
    }
}
  
const HistoricalTableContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoricalTable));

export default HistoricalTableContainer;