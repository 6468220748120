import React, { useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { loginAction } from '../actions';
import { isLogin, authCode } from '../utils/auth';
import { GetToken } from '../containers/Auth/authbutton';
import Container from '@mui/material/Container';
import SplashBody from '../components/splashbody';
import BreadcrumbHeader from '../components/breadcrumbheader';

// import configureStore from '../store'
// const { persistor } = configureStore();

// window.addEventListener("load", async (event) => {
//     let session = sessionStorage.getItem('persist:root')
//     console.log("PAGE IS FULLY LOADED", persistor, session);
// });

export default function SignIn() {
    let history = useHistory();
    let dispatch = useDispatch();
    const {auth} = useSelector(state => state);
    useEffect(() => {
        if(history.location.search !== '') {
            let token = async () => {
                    await GetToken()
                    return dispatch(loginAction(authCode()))
                    // return (isLogin)? history.push('/useraccount') : null
            }
            token()
        }
    },[dispatch, history]);

    useEffect(() => {
      if(isLogin && auth.isAuthenticated) {
        return history.push('/useraccount')
      }
    
    }, [auth.isAuthenticated, history])
    
    
    return (
        <div className="home-ct">
            <Container>      
                <BreadcrumbHeader />
                <SplashBody />
            </Container>
        </div>
    )
}