import React, { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TablePointsGiven from '../../components/tablepointsgiven'
import TablePointsEarned from '../../components/tablepointsearned'
import TablePointsRedeemed from '../../components/tablepointsredeemed';

const data = [
    ["Gabby George",1, "Good Job!", "Shared", "Sep 14,2021"],
    [
      "Aiden Lloyd",2,
      "", "Give",
      "Jun 26,2021"
    ],
    ["Jaden Collins", 1,"Test Comment", "Give","Jun 1,2021"],
    ["Franky Rees", 2,"Test Comment 2", "Shared", "May 14,2021"],
    ["Johnny Jones", 1,"Test Comment 3","Shared", "Jan 20,2021"],
    ["Jimmy Johns", 2,"", "Give","Jan 1,2021"],
    ["Jack Jackson", 1,"", "Give","Dec 31,2020"],
    ["Joe Jones", 1,"Test Comment", "Give","November 14,2020"],
    ["Jacky Jackson", 4,"Some Comment", "Give","October 14,2020"],
    ["Jo Jo", 1,"Comment A", "Give","October 3,2020"],
    ["Donna Marie",2, "Comment B","Give", "September 17,2020"]
  ];


export default function HistoricalTable() {
  const [value, setValue] = React.useState('1');
  const [ptsGiven, setPtsGiven] = useState([]);

  useEffect(() => {
    setPtsGiven(data);
    }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext 
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Points Earned" value="1" />
            <Tab label="Points Given/Shared" value="2" />
            <Tab label="Points Redeemed" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><TablePointsEarned/></TabPanel>
        <TabPanel value="2"><TablePointsGiven data={ptsGiven}/></TabPanel>
        <TabPanel value="3"><TablePointsRedeemed/></TabPanel>
      </TabContext>
    </Box>
  );
}