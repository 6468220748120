import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userprofile } from '../actions';
import Header from "../components/header";
import AccountHeaderContainer from '../containers/AccountHeader/container'
import BreadcrumbHeader from '../components/breadcrumbheader'
import ProductContainer from '../containers/Products/container'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Redeem() {
    let [open, setOpen] = useState(false);
    let dispatch = useDispatch();
    let loading = useSelector((state) => state.userObject.isFetching);

    useEffect(()=>{
        dispatch(userprofile());
    }, [dispatch]);

    useEffect(() => {
        setOpen(loading)
      }, [loading]);

    return (
        <div className="home-ct">
            <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Header/>
            <BreadcrumbHeader />
            <AccountHeaderContainer />
            <ProductContainer />
        </div>
    )
}
