import React, { useState } from 'react';
import {connect, useSelector} from  'react-redux';
import {bindActionCreators} from 'redux'
import {withRouter } from 'react-router-dom'
import { submitAdminGiveForm,resetAdminGiveForm } from '../../actions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm  } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import GetColleagueInfo from '../../components/autocomplete';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import axios from 'axios';
import { authCode } from '../../utils/auth';

const AdminGiveForm = (props) => {
    const [open, setOpen] = useState(false);
    const [acctId, setAcctId] = useState(0);
    const [loading, setIsLoading] = useState(false);
    const {user} = useSelector((state) => state.userObject);
    const formstatus = useSelector((state) => state.adminforms);
    const { handleSubmit, reset, control, watch } = useForm();
    const [regions, setRegions] = useState([]);  


    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log('watch:',value, name, type));
        return () => subscription.unsubscribe();
      }, [watch]);

    React.useEffect(() => {
        let profile = user;
        setAcctId(profile.acct_id);
    },[user]);

    React.useEffect(()=> {
        setOpen(formstatus.isFetching);
    }, [formstatus]);

    React.useEffect(() => {
        let getregions = async () => {
            let token = JSON.parse(authCode()).accessToken
            axios.get(`${process.env.REACT_APP_BASE_URL}/regions`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
            }).then((res) => {
              setRegions(res.data.regions);
            })
        }
        getregions();
    },[])

    
    const onSubmit = (data) => {
        setIsLoading(true);
        let formdata = [{
            "acct_id": acctId, 
            "receiver": {
                "name":`${data.to.first_name} ${data.to.last_name}`,
                "email":`${data.to.work_email}`
            },
            "giver": {
                "name":`${data.from.first_name} ${data.from.last_name}`,
                "email":`${data.from.work_email}`
            }, 
            "core_value": data.corevalue, 
            "amount": data.amount, 
            "giver_group": data.giverregion, 
            "receiver_group": data.receiverregion, 
            "comments": data.comment,
            "admin_notes": data.admin_notes,
            "created_by": user.fullname
        }]
        console.log(formdata)
        props.submitAdminGiveForm(formdata)
        
        setTimeout(() => {
            props.resetAdminGiveForm();
            window.location.assign("./useraccount"); 
        }, 3500)
    };

    return (
        <form  onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="bootstrap-input">
                Select a colleague to give points <strong>FROM</strong>
        </InputLabel>
        <GetColleagueInfo name={"from"} control={control}/>
        <br/>
        <InputLabel htmlFor="bootstrap-input">
                Select a colleague to give points <strong>TO</strong>
        </InputLabel>
        <GetColleagueInfo name={"to"} control={control}/>
        <br/>

        <Controller
            name={"amount"}
            control={control}
            defaultValue={1}
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Points
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="amount"
                        id="amount"
                        label="amount"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        value={value}
                        onChange={onChange}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                    </Select>
                </>
            )}
        />

        <Controller
            name={"corevalue"}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Pick a core value / emphasis area
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="corevalue"
                        id="corevalue"
                        label="Age"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        value={value}
                        onChange={onChange}
                    >
                        <MenuItem value={'Collaboration'}>Collaboration</MenuItem>
                        <MenuItem value={'Dedication'}>Dedication</MenuItem>
                        <MenuItem value={'Education'}>Education</MenuItem>
                        <MenuItem value={'Fiscal Responsibility'}>Fiscal Responsibility</MenuItem>
                        <MenuItem value={'Integrated Transportation'}>Integrated Transportation</MenuItem>
                        <MenuItem value={'Integrity'}>Integrity</MenuItem>
                        <MenuItem value={'N/A'}>N/A</MenuItem>
                        <MenuItem value={'Passion'}>Passion</MenuItem>
                        <MenuItem value={'Quality'}>Quality </MenuItem>
                        <MenuItem value={'Safety'}>Safety</MenuItem>
                        <MenuItem value={'Transparency'}>Transparency</MenuItem>
                        <MenuItem value={'Trust'}>Trust</MenuItem>
                    </Select>
                </>
            )}
          />

        <Controller
            name={"giverregion"}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Giver Group
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="giverregion"
                        id="giverregion"
                        label="Giver Region"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        value={value}
                        onChange={onChange}
                    >
                        {regions.map(e => {
                            return (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </>
            )}
        />

        <Controller
            name={"receiverregion"}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Receiver Group
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        labelId="receiverregion"
                        id="receiverregion"
                        label="Receiver Region"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        value={value}
                        onChange={onChange}
                    >
                        {regions.map(e => {
                            return (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </>
            )}
        />

        <Controller
            name={"comment"}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Leave A Comment
                    </InputLabel>
                    <TextField
                        id="outlined-textarea"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        multiline
                        fullWidth={true}
                        rows={3}
                        placeholder="Limited to 1500 characters..."
                        value={value}
                        onChange={onChange}
                    />
                </>
            )}
        />

        <Controller
            name={"admin_notes"}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
                <>
                    <InputLabel htmlFor="bootstrap-input">
                        Admin Notes(For admin purposes only)
                    </InputLabel>
                    <TextField
                        id="outlined-textarea"
                        style={{ marginTop: 0,marginBottom: 20 }}
                        multiline
                        fullWidth={true}
                        rows={3}
                        placeholder="Limited to 1500 characters..."
                        value={value}
                        onChange={onChange}
                    />
                </>
            )}
        />
        <DialogActions>
            <Button onClick={() => {reset(); props.close()}} sx={{color:'#9b9b9b'}}>Cancel</Button>
            {!loading ? <Button type='submit' onClick={()=>{handleSubmit(onSubmit); }} variant={"outlined"}>Submit</Button> : null}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {formstatus.status === 'success' ? <Alert severity="success">{formstatus.message}</Alert> : null}
                {formstatus.status === 'error' ? <Alert severity="error">{formstatus.message}</Alert> : null}
            </Stack>
        </DialogActions>
        </form>
      );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ submitAdminGiveForm, resetAdminGiveForm }, dispatch)
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error, auth, userObject, persons, forms, adminforms } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error,
        auth,
        userObject,
        persons,
        forms,
        adminforms
    }
}

const AdminGiveFormContainer= withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminGiveForm));

export default AdminGiveFormContainer;
