import React, { Component } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import HistoricalTableContainer from '../HistoricalTables/container';


export default class AccountTxComponent extends Component {
    render() {
        return (
            <Container sx={{marginTop:'2em'}}>
                <Paper sx={{ p: 2, margin: 'auto', maxWidth: '100%', flexGrow: 1 }} elevation={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={8}>
                            <Typography component="div" variant="h5">
                                Transaction History
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Link to="/useraccount">   {`< Back to User Account`} </Link>
                        </Grid>
                        <Grid item xs={12} md={12}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <HistoricalTableContainer />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}
