import * as React from 'react';
import {Link, useLocation} from 'react-router-dom'
import Container from '@mui/material/Container'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';

const BreadcrumbHeader = () => {
    const location = useLocation();
    
    const renderBreadcrumbs = () => {
        if(location.pathname === '/useraccount'){
            return <Breadcrumbs separator="›" aria-label="breadcrumb"><Link to="/useraccount">User Account</Link></Breadcrumbs>
        }
        else if(location.pathname === '/redeem'){
            return <Breadcrumbs separator="›" aria-label="breadcrumb"><Link to='/useraccount'>User Account</Link><Link to="/redeem">Redeem</Link></Breadcrumbs>
        }
        else if(location.pathname === '/usertransactions'){
            return <Breadcrumbs separator="›" aria-label="breadcrumb"><Link to='/useraccount'>User Account</Link><Link to="/usertransactions">User Transactions</Link></Breadcrumbs>
        }
        // else if(location.pathname === '/admin'){
        //     return <Breadcrumbs separator="›" aria-label="breadcrumb"><Link to='/useraccount'>User Account</Link></Breadcrumbs><Breadcrumbs separator="›" aria-label="breadcrumb">Admin</Breadcrumbs></span>
        // }
        else {
            return <Breadcrumbs separator="›" aria-label="breadcrumb"></Breadcrumbs>
        }
    }

    return(
        <Container>
            <div className="breadcrumb-hd-cont">
                <Stack spacing={2}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link to="/">Home</Link>
                    {renderBreadcrumbs()}
                </Breadcrumbs>
                </Stack>
            </div>
        </Container>
    )
}

export default BreadcrumbHeader;