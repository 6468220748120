import React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TablePointsGiven from '../../components/tablepointsgiven'
import TablePointsEarned from '../../components/tablepointsearned'
import TablePointsRedeemed from '../../components/tablepointsredeemed';

export default function UserTable() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext 
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="user tables">
            <Tab label="Points Earned" value="1" />
            <Tab label="Points Given/Shared" value="2" />
            <Tab label="Points Redeemed" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><TablePointsEarned/></TabPanel>
        <TabPanel value="2"><TablePointsGiven/></TabPanel>
        <TabPanel value="3"><TablePointsRedeemed/></TabPanel>
      </TabContext>
    </Box>
  );
}