import React, { Component } from 'react'
import Header from "../components/header";
import AccountTxContainer from '../containers/AccountTx/container';
import AccountHeaderContainer from '../containers/AccountHeader/container';
import BreadcrumbHeader from '../components/breadcrumbheader';

export default class UserTransactions extends Component {
    render() {
        return (
            <div className="home-ct">
                <Header/>
                <BreadcrumbHeader />
                <AccountHeaderContainer />
                <AccountTxContainer />
            </div>
        )
    }
}
