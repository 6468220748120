import React from 'react';
import logo from "../assets/extra_mile_logo_1_10.png";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper';
import { isLogin } from '../utils/auth'
import { Logout } from '../containers/Auth/authbutton';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    textAlign: 'right',
    elevation:0
    // color: theme.palette.text.secondary,
  }));

const Header = () => {
    return (
        <div className="top-header">
            <Container className="sub-header" fluid="true">
                <Grid container spacing={3} >
                    <Grid style={{paddingLeft:"25px"}}>
                        <img src={logo} width="200px" height="100px" alt="udot desktop logo" />
                    </Grid>
                    <Grid item xs={0} sm={6} md={6} lg={8} xl={8}>
                        <Item></Item>
                    </Grid>
                    <Grid item xs="auto">
                        {
                            isLogin()
                            ? <Logout/>
                            : <div>{console.log('login status is ' + isLogin())}</div>
                        }
                    </Grid> 
                </Grid>
            </Container>
        </div>
    );
}; 

export default Header;