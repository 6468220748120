import React, { useState, useEffect } from 'react'
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { authCode } from '../utils/auth';
import { useSelector } from 'react-redux'
import { convertISODateToDateTime } from "../helpers/datetime";

export default function TablePointsRedeemed() {
  const [responsive] = useState("simple");
  const [tableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [data, setData] = useState([])
  const {user} = useSelector((state) => state.userObject);
  
  useEffect(() => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    async function fetchData() {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/orders/${user.acct_id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
      }).then((res) => {
        setData(res.data);
      })
    }
    fetchData()
  },[user])

  const columns = [
    {
      name: "picture_url",
      label: "Image",
      options: {
        customBodyRender: (value) => {
          return (
            <img src={value} width="72" height="52" alt={value}>
            </img>
          )
        }
      }
    },
    { label:"Product", name: "product_name", options: { filterOptions: { fullWidth: true } } },
    { label:"Color", name: "color" },
    { label:"Size", name: "size" },
    { label:"Additional Options", name: "add_options", options:{ display: false } },
    { label:"Points", name: "points" },
    { label:"Redeem Create Date", name: "created_date", options:{
      customBodyRender: (val) => {
        let isoDate = convertISODateToDateTime(val);
        var d = new Date(isoDate);
        return d.toLocaleDateString('en-US'); 
      }
    } },
    { label:"Order Processing", name: "ordered",  
      options: { 
      customBodyRender: (val) => {
        return val;
      }
    }},
    { label:"Sent To Group Contact", name: "distributed", 
      options: { 
      customBodyRender: (val) => {
        return val;
      }
    }},
    { label:"Order Claimed", name: "claimed", 
      options: { 
      customBodyRender: (val) => {
        return val;
      }
    }},
    { label:"Notes", name: "comments" },
    { label:"Update Date", name: "update_date", options:{ display: false } }
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
    selectableRows: 'none',
    textLabels: {
      body:{
        noMatch: "Fetching data ...",
      }
    }
  };

  return (
      <MUIDataTable
        data={data.data}
        columns={columns}
        options={options}
      />
  );
}


