import {combineReducers} from 'redux';
import auth from './authentication';
import products from './products';
import userObject from './user_object';
import forms from './forms';
import order from './order';
import { pointsearned, pointsgiven, pointsredeemed } from './transactions';
import orgnumber from './orgnumber';
import adminforms from './adminforms';

const rootReducer = combineReducers({
    auth,
    products,
    userObject,
    forms,
    order,
    pointsearned,
    pointsgiven,
    pointsredeemed,
    orgnumber,
    adminforms
});

export default rootReducer;