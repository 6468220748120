import React, { useState, useEffect } from 'react';
import {connect, useSelector} from  'react-redux';
import {bindActionCreators} from 'redux'
import {withRouter } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { fyquarterDB } from '../../helpers/fyquarter';
import { submitAdminRedeemForm, resetAdminRedeemForm, userorgnumber } from '../../actions';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import GetColleagueInfo from '../../components/autocomplete';

import axios from 'axios';
import { authCode } from '../../utils/auth';


const AdminRedeemForm = (props) => {
    const {  handleSubmit, control, watch,register } = useForm();
    const [acctId, setAcctId] = useState(0);
    const {user} = useSelector((state) => state.userObject);
    const [open, setOpen] = useState(false);
    const products = useSelector((state) => state.products.productlist.data);
    const [selectedProduct, setSelectedProduct] = useState({});
    const formstatus = useSelector((state) => state.adminforms);
    const [loading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState([]);  


    useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log('watch:',value, name, type));
        return () => subscription.unsubscribe();
      }, [watch]);

    useEffect(() => {
        let profile = user;
        setAcctId(profile.acct_id);
    },[user]);

    useEffect(()=> {
        setOpen(formstatus.isFetching);
    },[formstatus.isFetching]);

    useEffect(() => {
        let getregions = async () => {
            let token = JSON.parse(authCode()).accessToken
            axios.get(`${process.env.REACT_APP_BASE_URL}/regions`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
            }).then((res) => {
              setRegions(res.data.regions);
            })
        }
        getregions();
    },[])


    const onSubmit = (data) => {
        let profile = user;
        setIsLoading(true);
        let orderPeriod= fyquarterDB();

        let formdata = [{
            "acct_id": acctId,
            "product_id":data.product.product_id,
            "orderer": {
                "name": `${data.to.first_name} ${data.to.last_name}`,
                "email": `${data.to.work_email}`
            },
            "points": data.product.pts_value, 
            "dollar_value": data.product.dollar_value, 
            "color": `${data.color}`, 
            "size": `${data.size}`,
            "region": `${data.memberregion}`,
            "org_number": data.to.org,
            "order_period": orderPeriod,
            "created_by": profile.workforce_id
        }]

        props.submitAdminRedeemForm(formdata);
        setTimeout(() => {
            props.resetAdminRedeemForm();
            window.location.assign("./redeem"); 
        }, 3500)
    };



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name={"product"}
                control={control}
                defaultValue=""
                rules={{ required: 'Product is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor="bootstrap-input">
                            Select A Product: *
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="product"
                            id="product"
                            label="product"
                            style={{ marginTop: 0,marginBottom: 20 }}
                            value={setSelectedProduct(value)}
                            onChange={onChange}
                        >
                            { products.map((option) => {
                                return (
                                    <MenuItem key={option.product_id} value={option}>
                                        {option.product_name} <br/>
                                        {` Points: ${option.pts_value} `} 
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        {value === '' ? <FormHelperText error sx={{marginTop:-2}}>A Product Value is Required! </FormHelperText> : null}
                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}><img src={value.picture_url} width="250px" height="150px"/><br/></div>
                        <i>{value.description}</i>
                       
                    </>
                )}
            />

            <br/>
            <br/>
            
            <Controller
                control={control}
                defaultValue=""
                name={"color"}
                render={({ field: { onChange, value } }) => (
                    <>
                    <InputLabel htmlFor="bootstrap-input">
                        Type in the color that you want <br/>
                        Options:{selectedProduct.color === '' ? 'N/A' : selectedProduct.color}
                    </InputLabel>
                    <TextField
                        autoFocus
                        style={{ marginTop: 0,marginBottom: 20 }}
                        id="color"
                        type="text"
                        value={value}
                        fullWidth
                        variant="outlined"
                        onChange={onChange}
                    />
                    </>
                )}
            />


            <Controller
                control={control}
                defaultValue=""
                name={"size"}
                render={({ field: { onChange, value } }) => (
                    <>
                    <InputLabel htmlFor="bootstrap-input">
                        Type in the size that you want <br/>
                        Options:{selectedProduct.size === "" ? 'N/A' : selectedProduct.size}
                    </InputLabel>
                    <TextField
                        autoFocus
                        style={{ marginTop: 0,marginBottom: 20 }}
                        id="color"
                        type="text"
                        value={value}
                        fullWidth
                        variant="outlined"
                        onChange={onChange}
                    />
                    </>
                )}
            />
            
            <InputLabel htmlFor="bootstrap-input">
                Select a colleague to redeem points <strong>FOR</strong>
            </InputLabel>
            <GetColleagueInfo name={"to"} control={control} {...register("to")}/>
            <br/>
            
            <Controller
                name={"memberregion"}
                control={control}
                defaultValue=""
                rules={{ required: 'Member Region is required!' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor="bootstrap-input">
                            Which group is your colleague a member of ?*
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="memberregion"
                            id="memberregion"
                            label="Member Region"
                            style={{ marginTop: 0,marginBottom: 20 }}
                            value={value}
                            onChange={onChange}
                        >
                            {regions.map(e => {
                                return (
                                    <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {value === '' ? <FormHelperText error sx={{marginTop:-2}}>Member Region is required! </FormHelperText> : null}
                    </>
                )}
            />
            <br/>

            <DialogActions>
                <Button onClick={() => {props.close(); }} sx={{color:'#9b9b9b'}}>Cancel</Button>
                { !loading ? <Button type='submit' onClick={()=>{handleSubmit(onSubmit)}} variant={"outlined"}>Submit</Button> : null}
                
            </DialogActions>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {formstatus.status === 'success' ? <Alert severity="success">{formstatus.message}</Alert> : null}
                {formstatus.status === 'error' ? <Alert severity="error">{formstatus.message}</Alert> : null}
            </Stack>
        </form>
      );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ submitAdminRedeemForm, resetAdminRedeemForm, userorgnumber }, dispatch)
}

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error, userObject,adminforms } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error,
        userObject,
        adminforms
    }
}

const AdminRedeemFormContainer= withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRedeemForm));

export default AdminRedeemFormContainer;
