import axios from 'axios';
import { authCode } from '../utils/auth';

const LOGIN_URL =  `${process.env.REACT_APP_AUTH_URL}/auth/google/login`;
const LOGOUT_URL = `${process.env.REACT_APP_AUTH_URL}/auth/google/logout`;

const PRODUCT_URL = `${process.env.REACT_APP_BASE_URL}/products`;
const USEROBJECT_URL = `${process.env.REACT_APP_BASE_URL}/user/object`;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const USEROBJECT_RESPONSE = 'USEROBJECT_RESPONSE';
export const USEROBJECT_REQUEST = 'USEROBJECT_REQUEST';
export const USEROBJECT_ERROR = 'USEROBJECT_ERROR';
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_LIST = 'PRODUCTS_LIST';
export const PERSONS_REQUEST = 'PERSONS_REQUEST';
export const PERSONS_RESPONSE = 'PERSONS_RESPONSE';
export const USRORGNUM_REQUEST = 'USRORGNUM_REQUEST';
export const USRORGNUM_RESPONSE = 'USRORGNUM_RESPONSE';
export const SUBMIT_GIVEFORM = 'SUBMIT_GIVEFORM';
export const SUBMIT_GIVEFORM_RESPONSE = 'SUBMIT_GIVEFORM_RESPONSE';
export const RESET_SUBMIT_GIVEFORM = 'RESET_SUBMIT_GIVEFORM';
export const SUBMIT_REDEEMFORM = 'SUBMIT_REDEEMFORM';
export const SUBMIT_REDEEMFORM_RESPONSE = 'SUBMIT_REDEEMFORM_RESPONSE';
export const RESET_SUBMIT_REDEEMFORM = 'RESET_SUBMIT_REDEEMFORM';
export const PTSEARNED_REQUEST = 'PTSEARNED_REQUEST';
export const PTSEARNED_RESPONSE = 'PTSEARNED_RESPONSE';
export const PTSGIVEN_REQUEST = 'PTSGIVEN_REQUEST';
export const PTSGIVEN_RESPONSE = 'PTSGIVEN_RESPONSE';
export const PTSREDEEMED_REQUEST = 'PTSREDEEMED_REQUEST';
export const PTSREDEEMED_RESPONSE = 'PTSREDEEMED_RESPONSE';

/* 
    * Admin Variables 
*/
export const SUBMIT_ADMIN_GIVEFORM = 'SUBMIT_ADMIN_GIVEFORM';
export const SUBMIT_ADMIN_GIVEFORM_RESPONSE = 'SUBMIT_ADMIN_GIVEFORM_RESPONSE';
export const RESET_ADMIN_GIVEFORM = 'RESET_ADMIN_GIVEFORM';
export const SUBMIT_ADMIN_REDEEMFORM = 'SUBMIT_ADMIN_REDEEMFORM';
export const SUBMIT_ADMIN_REDEEMFORM_RESPONSE = 'SUBMIT_ADMIN_REDEEMFORM_RESPONSE';
export const RESET_ADMIN_REDEEMFORM = 'RESET_ADMIN_REDEEMFORM';

export function loginAction(data) {
    return async function(dispatch){
        dispatch({type:LOGIN_REQUEST});

        try {
            const response = await axios.post(`${LOGIN_URL}`, data, {
                headers: {
                    'content-type': 'application/json'
                }
            });
            return dispatch({
                type: LOGIN_REQUEST_SUCCESS,
                payload: response
            });
        } catch (error) {
            return console.log(error);
        }
    }
}

export function logoutAction(data) {
    return async (dispatch) => {
        dispatch({type:LOGOUT_REQUEST});
        axios.post(`${LOGOUT_URL}`,data, {
            headers: {
                'content-type': 'application/json'
            }
        }).catch(error => console.log(error))   
    }
}

export function userprofile() {
    return function(dispatch) {
        let token = JSON.parse(authCode()).accessToken
        let idToken = JSON.parse(authCode()).idToken
        dispatch({ type: USEROBJECT_REQUEST });
        axios.get(`${USEROBJECT_URL}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Token':`${idToken}`
            }
        })
        .then(response => dispatch({
            type:USEROBJECT_RESPONSE,
            payload:response
        }))
        .catch(error => {console.log(error); dispatch({type:USEROBJECT_ERROR,payload:error})})
    }
};


export function productlist() {
    return async (dispatch) => {
        dispatch({type:PRODUCTS_REQUEST});
        let token = JSON.parse(authCode()).accessToken
        let idToken = JSON.parse(authCode()).idToken
        axios.get(`${PRODUCT_URL}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Token':`${idToken}`
            }
        })
        .then(response => dispatch({
            type:PRODUCTS_LIST,
            payload:response
        }))
        .catch(error => console.log(error))
    }
}



/*Get user's transaction*/
export const userPointsEarned = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:PTSEARNED_REQUEST})
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/pointsearned/${data}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })
    .then(response => dispatch({
        type:PTSEARNED_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error))
}

export const userPointsGiven = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:PTSGIVEN_REQUEST})
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/pointsgiven/${data}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })
    .then(response => dispatch({
        type:PTSGIVEN_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error))
}

export const userPointsRedeemed = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:PTSREDEEMED_REQUEST})
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/orders/${data}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })
    .then(response => dispatch({
        type:PTSREDEEMED_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error))
}

export const userorgnumber = (email) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:USRORGNUM_REQUEST})
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/user/getorgnumber?email=${email}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })
    .then(response => dispatch({
        type:USRORGNUM_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error))
}


/*Submit Give Form*/
export const submitGiveForm = (data) => async dispatch => {
        let token = JSON.parse(authCode()).accessToken
        let idToken = JSON.parse(authCode()).idToken
        dispatch({type:SUBMIT_GIVEFORM})
        // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/form/givepoints`, data, {
        return await axios.post(`${process.env.REACT_APP_BASE_URL}/form/givepoints`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Token':`${idToken}`
            }
        })    
        .then(response => dispatch({
            type:SUBMIT_GIVEFORM_RESPONSE,
            payload:response
        }))
        .catch(error => console.log(error));
        // return console.log('givepoints', response);
}

export const resetGiveForm = () => async dispatch => {
    return dispatch({type:RESET_SUBMIT_GIVEFORM})
}


/*Submit Redeem Form*/
export const submitRedeemForm = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:SUBMIT_REDEEMFORM})
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/form/redeempoints`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })    
    .then(response => dispatch({
        type:SUBMIT_REDEEMFORM_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error));
    // return console.log('givepoints', response);
}

export const resetRedeemForm = () => async dispatch => {
    return dispatch({type:RESET_SUBMIT_REDEEMFORM})
}

/* 
    * Admin Give Form 
*/
export const submitAdminGiveForm = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:SUBMIT_ADMIN_GIVEFORM})
    // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/form/givepoints`, data, {
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/form/admin/givepoints`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
    })    
    .then(response => dispatch({
        type:SUBMIT_ADMIN_GIVEFORM_RESPONSE,
        payload:response
    }))
    .catch(error => console.log(error));
    // return console.log('givepoints', response);
}

export const resetAdminGiveForm = () => async dispatch => {
return dispatch({type:RESET_ADMIN_GIVEFORM})
}


/* 
    * Admin Redeem Form
*/
export const submitAdminRedeemForm = (data) => async dispatch => {
    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    dispatch({type:SUBMIT_ADMIN_REDEEMFORM})
    return await axios.post(`${process.env.REACT_APP_BASE_URL}/form/admin/redeempoints`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
})    
.then(response => dispatch({
    type:SUBMIT_ADMIN_REDEEMFORM_RESPONSE,
    payload:response
}))
.catch(error => console.log(error));
// return console.log('givepoints', response);
}

export const resetAdminRedeemForm = () => async dispatch => {
return dispatch({type:RESET_ADMIN_REDEEMFORM})
}

