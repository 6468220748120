import React,{ useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import Container from '@mui/material/Container';
import logo from "../assets/extra_mile_logo.png";
import { LoginButton } from '../containers/Auth/authbutton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';


let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    bgcolor: 'background.paper',
    border: '1px solid #dadce0',
    boxShadow: 24,
    p: 2,
    textAlign: 'center'
};

const SplashBody = ()  => {
    const {auth} = useSelector(state => state)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
    const handleBackdropClick = (e) => {
        e.stopPropagation();
        return false;
    };

    useEffect(() => {
        if(auth.isFetching) {
            handleOpen()
        }
        else if (!auth.isFetching) {
            handleClose()
        }
    }, [auth])
    

    return (
        <Container>
            <div className="splash-body-cont">
                <img src={logo} width="250px" height="150px" alt="udot desktop logo"/>
                <p className='title element-space-height'>UDOT Extra Mile Program</p>
                <p style={{textAlign:'center'}} className="element-space-height">
                UDOT innovates transportation solutions that strengthen Utah’s economy and enhance quality of life, and is committed to improving Utah in everything that we do. UDOT’s most effective asset is our employees, who work hard to ensure a better tomorrow. We have an extensive skill set  among our workers and value the importance of recognizing individual excellence. This program responds to a need identified by employees on the Employee Advisory Committee and is therefore a program for the employees designed by the employees. ~ Carlos Braceras, UDOT Director
                </p>
                <div>
                    <br/>
                    <LoginButton/>
                </div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onBackdropClick={handleBackdropClick}
                    // disableEscapeKeyDown
                >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Logging In ...
                    </Typography>
                    </Box>
                </Modal>
            </div>
        </Container>
    )
}

export default SplashBody;
