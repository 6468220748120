import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import ProductComponent from './component';
import { productlist, userorgnumber } from '../../actions';

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({productlist, userorgnumber}, dispatch)};
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error,  persons, products, auth } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error,
        persons,
        products,
        auth
    }
}
  
const ProductsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductComponent));

export default ProductsContainer;