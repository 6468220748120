import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { authCode } from '../utils/auth';
import { useSelector } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { convertISODateToDateTime } from '../helpers/datetime';


export default function TablePointsEarned() {
  const [responsive] = useState("simple");
  const [tableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight] = useState("");
  const [searchBtn] = useState(true);
  const [downloadBtn] = useState(true);
  const [viewColumnBtn] = useState(true);
  const [filterBtn] = useState(true);
  const [data, setData] = useState([])
  const {user} = useSelector((state) => state.userObject);

  
  useEffect( () => {

    let token = JSON.parse(authCode()).accessToken
    let idToken = JSON.parse(authCode()).idToken
    async function fetchData() {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/pointsearned/${user.acct_id}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Token':`${idToken}`
        }
      }).then((res) => {
        setData(res.data);
      })
    }
    fetchData();
  },[user])

  const columns = [
    { label:"Giver Name", name: "giver_name", options: { filterOptions: { fullWidth: true } } },
    { label:"Points", name: "amount" },
    { label:"Value", name: "core_value" },
    { label:"Notes", name: "comments" },
    { label:"Type", name: "tx_type" },
    { label:"Group", name: "giver_group" },
    { label:"Date", name: "created_date", options:{
      customBodyRender: (val) => {
        let isoDate = convertISODateToDateTime(val);
        var d = new Date(isoDate);
        return d.toLocaleDateString('en-US'); 
      }
    }},
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    onTableChange: (action, state) => {
      return;
    },
    selectableRows: 'none',
    textLabels: {
      body:{
        noMatch: "Fetching data ...",
      }
    }
  };

  return (
      <MUIDataTable
        data={data.data}
        columns={columns}
        options={options}
      />
  );
}

