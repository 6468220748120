import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AdminRedeemFormContainer from '../Forms/adminredeemform'

const AdminRedeemFormButton = () => {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <React.Fragment>
            <ButtonGroup variant="text" aria-label="text button group">
                <Button variant="contained" color="primary"  onClick={handleClickOpen}>ADMIN REDEEM FORM</Button><br/>
            </ButtonGroup>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Employee Appreciation</DialogTitle>
                <DialogContent>
                <DialogContentText>
                Admin - Redeem Points 
                <br/>
                <br/>
                This form is used by Admins to give points. Any points given using this form will show as the colleague you are giving from.
                </DialogContentText>
                <br/>
                <AdminRedeemFormContainer close={handleClose}/>
                </DialogContent>
            </Dialog>


        </React.Fragment>
    )
}

export default AdminRedeemFormButton