// import React, { Component } from 'react'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { fyquarter } from '../../helpers/fyquarter';



export default function AccountHeaderComponent (props) {

      const [refresh,setRefresh] = useState();
      const [profile, setProfile] = useState({ name:'', initial:'', profileImage:'', points:0, redeemed:0, giveable:0, redeemable:0});
      const {user} = useSelector((state) => state.userObject);

      useEffect(() => {
        setTimeout(()=> {
          if (Object.keys(user).length === 0) {
            setRefresh({});
          }
          else if (user === undefined) {
            setRefresh({});
          }
          else {
            let userInitial = user.fullname.substring(0,1).toUpperCase();
            setProfile({
              name: user.fullname,
              initial: userInitial,
              giveable: user.giveable,
              redeemable:user.redeemable,
              points:user.balance
            });
          }
        },2000);
      }, [user]);
 
      
        return (
            <Container>

            <Paper sx={{ p: 2, margin: 'auto', maxWidth: '100%', flexGrow: 1 }} elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={3} sx={{textAlign:'center'}}>
                    <div className="acct-header-avatar">
                      {profile.profileImage === "" ? <Avatar sx={{ bgcolor: deepOrange[500], width: 72, height: 72  }}>{profile.profileImage}</Avatar> : <Avatar sx={{ bgcolor: deepOrange[500], width: 72, height: 72  }} src={profile.profileImage}>{profile.initial}</Avatar>}
                    </div>
                </Grid>
                <Grid item xs={6} md={3} sx={{textAlign:'justify'}}>
                  <div className="totalpoints-cont">
                    <div className="totalpoints-label">TOTAL POINTS:</div><div className="totalpoints-data">{profile.points}</div>
                  </div>
                  {/* <Typography gutterBottom variant="subtitle1" component="div">
                    TOTAL POINTS: {profile.points}
                  </Typography> */}
                  <Tooltip title="Points given" placement="left" arrow>
                    <Typography variant="body3" color="text.secondary">
                    {
                    user.is_voucher_admin 
                      ? <><strong>GIVEN: {Math.max(profile.giveable, 4) - profile.giveable} / {Math.max(profile.giveable, 4)}</strong></> 
                      : <><strong>GIVEN: {4 - profile.giveable} / 4 </strong></>
                    }
                    </Typography>
                  </Tooltip>
                  <br/>
                  <Tooltip disableFocusListener title="Amount redeemed current FY" placement="left" arrow>
                    <Typography variant="body3" gutterBottom color="text.secondary">
                      <strong>REDEEMED: {profile.redeemable} </strong>
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}> 
                  <p className="acct-header-disclaimer">
                  July 1 employees will receive 4 UDOT points to GIVE to coworkers. Max number of Extra Mile Points to "GIVE" is 4 in a fiscal year. You can also "SHARE" points that you have earned from your co-workers.  Any of the 4 UDOT points that you have not GIVEN by the end of the fiscal year will be removed and you will receive 4 new UDOT points to be used in the upcoming fiscal year.
                  <br/>
                  <br/>
                  Employees can not redeem more than 16 points per fiscal year. Any points that you have EARNED will roll over to the next fiscal year. The points you earn will NOT EXPIRE. 
                  </p>
                  <p className="acct-header-disclaimer">
                  NEXT ORDER DEADLINE <br/><strong>{fyquarter()}</strong>
                  </p>
                </Grid>
            </Grid>
          </Paper>
          </Container>
        )
    }

