// import React, { Component } from 'react'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container,Grid, Paper, Typography, Button ,Box, Stack, Alert} from '@mui/material'
import { Dialog,DialogContent,DialogContentText,DialogTitle} from '@mui/material'
import RedeemIcon from '@mui/icons-material/Redeem';
import SendIcon from '@mui/icons-material/Send';
import GiveFormContainer from '../Forms/giveform'
import AdminGiveFormButton from './admin'
import UserTable from '../Tables/usertxns'


export default function AccountBodyComponent () {
    const [open, setOpen] = useState(false);
    const [refresh,setRefresh] = useState();
    const [pts, setPts] = useState({ ptsAtg:0, ptsAte:0, ptsAtr:0, giveable:0, redeemable:0 });
    const [isVoucherAdmin, setIsVoucherAdmin] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const {user} = useSelector((state) => state.userObject);

    useEffect(() => {

        if (Object.keys(user).length === 0) {
            setRefresh({});
        }
        else if (user === undefined) {
            setRefresh({});
        }
        else {
            setPts({
                giveable: user.giveable,
                redeemable: user.redeemable,
                ptsAtg: user.ptsAtg,
                ptsAte: user.ptsAte,
                ptsAtr: user.ptsAtr
            })
            setIsVoucherAdmin(user.is_voucher_admin);
            setIsAdmin(user.is_employee_admin);
        }

    }, [user]);

    return (
        <Container sx={{marginTop:'2em'}}>
        <Paper sx={{ p: 2, margin: 'auto', maxWidth: '100%', flexGrow: 1 }} elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={8}>
                    <Typography component="div" variant="h5">
                        EXTRA MILE
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    { isAdmin === true ?  <AdminGiveFormButton/>  : null }
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography component="div" variant="h8">
                    If you have questions or comments please email extramile@utah.gov
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="primary" endIcon={<SendIcon />} onClick={() => setOpen(true)}>
                        { pts.giveable === 0 ? "Share Points" : "Give Points"}
                    </Button>
                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>Employee Appreciation</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                        { pts.giveable > 0 ? `Giveable Points: ${pts.giveable}` : `Shareable Points: ${pts.redeemable}`}
                        <br/><br/>
                        Acknowledgement of a job well done is a gift you give in return for what was given to you. This is your time to compliment and praise, do it thoughtfully and with purpose. All fields are required to give an Extra Mile point.
                        </DialogContentText>
                        <br/>
                        {pts.giveable <= 0 ? <Alert severity="error">You DO NOT have any more points to give away! Any points you give will be SHARED from your points.</Alert> : null}
                        <br/>
                        <GiveFormContainer close={() => setOpen(false)}/>
                        </DialogContent>
                    </Dialog>
                    <Link to='/redeem'>
                    <Button variant="contained" color="secondary" endIcon={<RedeemIcon />}>
                        Redeem Points
                    </Button>
                    </Link>
                </Stack>
                </Grid>
                
                
                <Grid item xs={6} sm={6} md={6} sx={{margin:"3.0em 0 1em 0"}}>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Box className="pts-alltime-box">Points Earned <br/>(All Time):<br/> {pts.ptsAte}</Box>
                    <Box className="pts-alltime-box">Points Given <br/>(All Time):<br/> {pts.ptsAtg}</Box>
                    <Box className="pts-alltime-box">Points Redeemed <br/>(All Time):<br/> {pts.ptsAtr}</Box>
                </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <UserTable />
                    {/* <div className="acct-body-txns-btn"><Link to="/usertransactions">View More</Link></div> */}
                </Grid>
            </Grid>
        </Paper>
    </Container>
    )
}
