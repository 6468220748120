import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store';
const { store, persistor } = configureStore();

const theme = createTheme({
  palette: {
    primary: {
      main: '#173862',
      dark: '#173862',
      light:'#cce2ff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#d14124',
      dark: '#d14124',
      light: '#ffa733',
      contrastText: '#fff',
    },
    custom: {
      buttonBorder: '#dadce0'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#85b7d9",
          fontWeight: "bold"
        }
      }, 
      
      focused: {}
    },
  },
});

console.log(process.env)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={theme}>
          <App />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
