import React from 'react'

function NotFound() {
    return (
        <div>
            ERROR 404
            <br/>
            PAGE NOT FOUND!
        </div>
    )
}

export default NotFound
