  import { PRODUCTS_REQUEST , PRODUCTS_LIST } from '../actions/index';

export default function products(state = {
    isFetching: false,
    productlist:[]
  }, action) {
        switch (action.type) {
            case PRODUCTS_REQUEST:
                return {...state, isFetching: true, error: null,productlist:[]}
            case PRODUCTS_LIST:
                return {...state, isFetching: false, productlist: action.payload.data}
            default:
                return state
        }
  }