import React, { useState, useEffect } from 'react';
import {connect, useSelector} from  'react-redux';
import {bindActionCreators} from 'redux'
import {withRouter } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import { fyquarterDB } from '../../helpers/fyquarter';
import { submitRedeemForm,resetRedeemForm } from '../../actions';
import { idToken } from '../../utils/auth';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import axios from 'axios';
import { authCode } from '../../utils/auth';

const RedeemForm = (props) => {
    const {  handleSubmit, control, watch } = useForm();
    const [acctId, setAcctId] = useState(0);
    const {user} = useSelector((state) => state.userObject);
    const [open, setOpen] = useState(false);
    const formstatus = useSelector((state) => state.order);
    const {auth} = useSelector((state) => state)

    const orgnumber = "unit" in idToken() ? idToken().unit : 0;

    const [loading, setIsLoading] = useState(false);
    const [regions, setRegions] = useState([]); 

    useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log('watch:',value, name, type));
        return () => subscription.unsubscribe();
      }, [watch]);

    useEffect(() => {
        let profile = user;
        setAcctId(profile.acct_id);
    }, [user]);

    useEffect(()=> {

        setOpen(formstatus.isFetching);
    }, [formstatus]);

    useEffect(() => {
        let getregions = async () => {
            let token = JSON.parse(authCode()).accessToken
            axios.get(`${process.env.REACT_APP_BASE_URL}/regions`, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
            }).then((res) => {
              setRegions(res.data.regions);
            })
        }
        getregions();
    },[])

    const validateRedeemable = (bal, redeempts, pts) => {
            let balance = parseInt(bal);
            let redeem = parseInt(redeempts)
            let points = parseInt(pts)
            let total = redeem + pts

            if(total <= 16 && points <= balance){
                return true
            }
            else return false
    }


    const onSubmit = (data) => {
        let profile = user;
        setIsLoading(true);
        let orderPeriod= fyquarterDB();
        let product = props.data
   
        let formdata = [{
            "acct_id": acctId,
            "product_id":product.product_id,
            "orderer": {
                "name": `${profile.fullname}`,
                "email": `${profile.email}`
            },
            "points": product.pts_value, 
            "dollar_value": product.dollar_value, 
            "color": `${data.color}`, 
            "size": `${data.size}`,
            "region": `${data.memberregion}`,
            "org_number": data.orgnumber,
            "order_period": orderPeriod,
            "created_by": user.workforce_id
        }]

        props.submitRedeemForm(formdata)
        setTimeout(() => {
            props.resetRedeemForm();
            window.location.assign("../redeem"); 
        }, 3500)
    };



    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            { props.data.color.length > 1 ? <Controller
                control={control}
                defaultValue=""
                name={"color"}
                render={({ field: { onChange, value } }) => (
                    <>
                    <InputLabel htmlFor="bootstrap-input">
                        Type in the color that you want
                    </InputLabel>
                    <TextField
                        autoFocus
                        style={{ marginTop: 0,marginBottom: 20 }}
                        id="color"
                        type="text"
                        value={value}
                        fullWidth
                        variant="outlined"
                        onChange={onChange}
                    />
                    </>
                )}
            />
            : null
            }

            { props.data.size.length > 1 ? <Controller
                            control={control}
                            defaultValue=""
                            name={"size"}
                            render={({ field: { onChange, value } }) => (
                                <>
                                <InputLabel htmlFor="bootstrap-input">
                                    Type in the size that you want
                                </InputLabel>
                                <TextField
                                    autoFocus
                                    style={{ marginTop: 0,marginBottom: 20 }}
                                    id="color"
                                    type="text"
                                    value={value}
                                    fullWidth
                                    variant="outlined"
                                    onChange={onChange}
                                />
                                </>
                            )}
                        />
                        : null
                        }
            
            <Controller
                name={"memberregion"}
                control={control}
                defaultValue=""
                rules={{ required: 'Member Region is required!' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor="bootstrap-input">
                            Which Group Are You A Member Of ?*
                            <br/>
                            (this will be where you will pick up the item from)<a href="https://docs.google.com/spreadsheets/d/1krnfTQNzXOOIC6inIL9JRc3L0KIp_MoVOOFjbRMQWZk/edit" target="_blank" rel="noreferrer">Admin List</a>
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="memberregion"
                            id="memberregion"
                            label="Member Region"
                            style={{ marginTop: 0,marginBottom: 20 }}
                            value={value}
                            onChange={onChange}
                        >
                            {regions.map(e => {
                                return (
                                    <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {value === '' ? <FormHelperText error sx={{marginTop:-2}}>Member Region is required! </FormHelperText> : null}
                    </>
                )}
            />
            <br/>
            <Controller
                name={"orgnumber"}
                control={control}
                defaultValue={orgnumber}
                rules={{ required: 'Organization Number is required!' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor="bootstrap-input">
                            Organization Number*
                        </InputLabel>
                        <TextField
                            autoFocus
                            style={{ marginTop: 0,marginBottom: 20 }}
                            id="ornumber"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    </>
                )}
            />

                    

            <DialogActions>
                <Button onClick={() => {props.close(); }} sx={{color:'#9b9b9b'}}>Cancel</Button>
                {   validateRedeemable (user.balance, user.redeemable, props.data.pts_value)
                    ? <div>{!loading ? <Button type='submit' onClick={()=>{handleSubmit(onSubmit)}} variant={"outlined"}>Submit</Button> : null}</div>
                    : <Alert severity="error">Not Enough Points To Redeem!</Alert>
                }
            </DialogActions>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {formstatus.status === 'success' ? <Alert severity="success">{formstatus.message}</Alert> : null}
                {formstatus.status === 'error' ? <Alert severity="error">{formstatus.message}</Alert> : null}
            </Stack>
        </form>
      );
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ submitRedeemForm, resetRedeemForm }, dispatch)
}

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error, auth, userObject, persons, order } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error,
        auth,
        userObject,
        persons,
        order
    }
}

const RedeemFormContainer= withRouter(connect(mapStateToProps, mapDispatchToProps)(RedeemForm));

export default RedeemFormContainer;
