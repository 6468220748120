import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import AccountBodyComponent from './component'
import { userprofile, submitGiveForm, userPointsEarned, userPointsGiven, userPointsRedeemed } from '../../actions'


const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({userprofile, submitGiveForm, userPointsEarned, userPointsGiven, userPointsRedeemed}, dispatch)};
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error, auth, userObject, forms, pointsearned, pointsgiven, pointsredeemed } = state.auth
    return {
        isAuthenticated,
        accessToken,
        error,
        auth,
        userObject,
        forms,
        pointsearned, 
        pointsgiven,
        pointsredeemed 
    }
}

const AccountBodyContainer= withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountBodyComponent));

export default AccountBodyContainer;
