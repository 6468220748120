import React, { Component } from 'react'
import Header from "../components/header";
import NotFound from '../components/notfound';
import BreadcrumbHeader from '../components/breadcrumbheader';

export default class NotFoundView extends Component {
    render() {
        return (
            <div className="home-ct">
                <Header/>
                <BreadcrumbHeader/>
                <NotFound />
            </div>
        )
    }
}