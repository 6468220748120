import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import RedeemFormContainer from '../containers/Forms/redeemform';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1.0em",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
  }
}))(Tooltip);

export default function ProductCard(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
      const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item >
                <Card sx={{ maxWidth: 400, height: 500 }}>
                <CardMedia
                    component="img"
                    height="200px"
                    image={props.product.picture_url}
                    alt="image"
  
                />
                <CardContent>

                    <Typography gutterBottom variant="h6" component="div">
                    {props.product.product_name}
                    </Typography>

                    <Box component="div" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <CustomTooltip title={props.product.description} arrow>
                            <Typography paragraph variant="body2" color="text.secondary" sx={{height:100, paddingTop:1, overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                {props.product.description}
                            </Typography>
                        </CustomTooltip>
                    </Box>
                    
                    <Typography variant="body2" color="text.secondary" sx={{height:50,paddingTop:2, fontWeight:700}}>
                    Points: {props.product.pts_value}
                    <br/>
                    {console.log(props.product)}
                    {props.product.limited_qty ? `Quantity: ${props.product.limited_qty}` : null}
                    </Typography>
                    {props.product.limited_qty ? <Alert severity="info">This product is available in limited quantity!</Alert> : null}
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="secondary" onClick={handleClickOpen} >
                        Redeem
                    </Button>
                    <Dialog open={open} onClose={handleClose} sx={{minWidth:"300px"}}>
                        <DialogTitle>{props.title}</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                        Are You Sure You Would Like To Redeem This Item?<br/><br/>
                        Points: {props.product.pts_value} <br/><br/>
                        { props.product.color !== undefined ? `Available Colors: ${props.product.color}` : null}
                        <br/>
                        { props.product.size === undefined ?  null : `Available sizes: ${props.product.size}`}
                        </DialogContentText>
                        <br/>
                        <RedeemFormContainer data={props.product} close={handleClose}/>
                        </DialogContent>
                        </Dialog>
                </CardActions>
                </Card>
        </Grid>
    );
}