import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react'
import whitelogo from '../assets/UdotWhiteLogo.png'

const Footer = () => {
    return (
        <footer className="footer-ct">
            <Container>
                <Grid>
                    <Grid.Row centered columns={6}>
                        <Grid.Column>
                            <Image src={whitelogo} className="footer-img"/>
                        </Grid.Column>
                        <Grid.Column className="footer-cont">
                            <a className="footer-link"  href="https://docs.google.com/forms/d/e/1FAIpQLSdFWwiidgFIzSu4ceyPG-aviIWRcf1Ck7PJjeBpeKhw1xDb6Q/viewform?vc=0&c=0&w=1&flr=0" target="_blank" rel="noreferrer">PRODUCTS IDEA</a>
                        </Grid.Column>
                        <Grid.Column className="footer-cont">
                            <a className="footer-link" href="mailto:extramile@utah.gov" target="_blank" rel="noreferrer">SUPPORT</a>
                        </Grid.Column>
                        <Grid.Column className="footer-cont">
                            <a className="footer-link"  href="https://www.utah.gov/support/disclaimer.html" target="_blank" rel="noreferrer">TERMS OF USE</a>
                        </Grid.Column>
                        <Grid.Column className="footer-cont">
                            <a className="footer-link"  href="https://www.udot.utah.gov/connect/" target="_blank" rel="noreferrer">UDOT.UTAH.GOV</a>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Container>
        </footer>
    );
};


export default Footer;