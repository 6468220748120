import { PTSEARNED_REQUEST , PTSEARNED_RESPONSE,PTSGIVEN_REQUEST, PTSGIVEN_RESPONSE, PTSREDEEMED_REQUEST, PTSREDEEMED_RESPONSE } from '../actions/index';

export function pointsearned(state = {
    isFetching: false,
    earned:[]
  }, action) {
        switch (action.type) {
            case PTSEARNED_REQUEST:
                return {...state, isFetching: true, earned:[]}
            case PTSEARNED_RESPONSE:
                return {...state, isFetching: false, earned:action.payload.data}
            default:
                return state
        }
  }
  
export function pointsgiven(state = {
    isFetching: false,
    given:[]
  }, action) {
        switch (action.type) {
            case PTSGIVEN_REQUEST:
                return {...state, isFetching: true, given:[]}
            case PTSGIVEN_RESPONSE:
                return {...state, isFetching: false, given:action.payload.data}
            default:
                return state
        }
  }

export function pointsredeemed(state = {
    isFetching: false,
    redeem:[]
  }, action) {
        switch (action.type) {
            case PTSREDEEMED_REQUEST:
                return {...state, isFetching: true, redeem:[]}
            case PTSREDEEMED_RESPONSE:
                return {...state, isFetching: false, redeem:action.payload.data}
            default:
                return state
        }
  }