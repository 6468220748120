  
import { USEROBJECT_REQUEST , USEROBJECT_RESPONSE, USEROBJECT_ERROR } from '../actions/index';

export default function userObject(state = {
    isFetching: false,
    user:{},
    error: null
  }, action) {
        switch (action.type) {
            case USEROBJECT_REQUEST:
                return {...state, isFetching: true, user:{}}
            case USEROBJECT_RESPONSE:
                return {...state, isFetching: false, user:action.payload.data.data}
            case USEROBJECT_ERROR:
                return {...state, isFetching: false}
            default:
                return state
        }
  }