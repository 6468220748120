import {connect} from  'react-redux';
import {bindActionCreators} from 'redux'
import {withRouter} from 'react-router-dom'
import AccountHeaderComponent from './component'
// import {  } from '../../actions';

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({}, dispatch)};
   }

const mapStateToProps = (state) => {
    const { isAuthenticated, accessToken, error,profile, auth, userObject} = state.auth
    return {
        isAuthenticated,
        accessToken,
        profile,
        auth,
        userObject,
        error
    }
}
  
const AccountHeaderContainer= withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHeaderComponent));

export default AccountHeaderContainer;
