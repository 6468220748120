const fyquarter = () => {
        
    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth();

    switch(true) {
        case mm < 3 :
            return `4/1/${yyyy}`;
        case mm < 6 :
            return `7/1/${yyyy}`;
        case mm < 9 :
            return `10/1/${yyyy}`;
        case mm < 12 :
            return `1/1/${yyyy+1}`;
        default :
            return 'Error: Unable to get date.';
    }

}

const fyquarterDB = () => {
        
    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth();

    switch(true) {
        case mm < 3 :
            return `${yyyy}-4-1`;
        case mm < 6 :
            return `${yyyy}-7-1`;
        case mm < 9 :
            return `${yyyy}-10-1`;
        case mm < 12 :
            return `${yyyy+1}-1-1`;
        default :
            return 'Error: Unable to get date.';
    }

}

module.exports = {
    fyquarter,
    fyquarterDB
}