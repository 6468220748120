import {LOGIN_REQUEST ,LOGIN_REQUEST_SUCCESS, LOGOUT_REQUEST} from '../actions';

export default function auth(state = {
    isAuthenticated: false,
    isFetching: false,
    accessToken:"",
    profile:{},
    error: null
  }, action) {
        switch (action.type) {
            case LOGIN_REQUEST:
                return { ...state, isFetching: true, error: null}
            case LOGIN_REQUEST_SUCCESS:
                if(action.payload.data.status === 'success'){
                    return {...state, accessToken:action.payload.data.data[1].accessToken, profile:action.payload.data.data[0], isFetching: false, isAuthenticated:true}
                } else {
                    return {...state, error:action.payload.error, isFetching: false, isAuthenticated:false}
                }
            case LOGOUT_REQUEST:
                return { accessToken:"",profile:{}, isFetching:false, isAuthenticated:false}
            default:
                return state
        }
  }