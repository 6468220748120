import { SUBMIT_ADMIN_GIVEFORM , SUBMIT_ADMIN_GIVEFORM_RESPONSE, RESET_ADMIN_GIVEFORM, SUBMIT_ADMIN_REDEEMFORM, SUBMIT_ADMIN_REDEEMFORM_RESPONSE, RESET_ADMIN_REDEEMFORM } from '../actions/index';

export default function adminforms(state = {
    isFetching: false,
    status:'',
    error: null,
    message:''
  }, action) {
        switch (action.type) {
            case SUBMIT_ADMIN_GIVEFORM:
                return {...state, isFetching: true, status: 'pending',  error: null}
            case SUBMIT_ADMIN_GIVEFORM_RESPONSE:
                if(action.payload.data.status === 200) {
                    return {...state, isFetching: false, status:'success', message:action.payload.data.message}
                }
                else if (action.payload.data.status === 500) {
                    return {...state, isFetching: false, status:'error', message:action.payload.data.message}
                }
                break;
            case RESET_ADMIN_GIVEFORM:
                return {...state, isFetching: false, status: '',  error: null, message:''}
            case SUBMIT_ADMIN_REDEEMFORM:
                return {...state, isFetching: true, status: 'pending',  error: null}
            case SUBMIT_ADMIN_REDEEMFORM_RESPONSE:
                if(action.payload.data.status === 200) {
                    return {...state, isFetching: false, status:'success', message:action.payload.data.message}
                }
                else if (action.payload.data.status === 500) {
                    return {...state, isFetching: false, status:'error', message:action.payload.data.message}
                }
                break;
            case RESET_ADMIN_REDEEMFORM:
                return {...state, isFetching: false, status: '',  error: null, message:''}
            default:
                return state
        }
  }