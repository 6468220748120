import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ProductCard from '../../components/productcard';
import AdminRedeemFormButton from './admin';


export default function ProductComponent(props) {
        const [isLoading, setIsLoading] = useState(false);
        // const [products, setProducts] = useState([]);
        const [q, setQ] = useState("");
        const isAdmin = useSelector(state => state.userObject.user.is_employee_admin);
        const [products] = useSelector((state) => [state.products]);
        
        useEffect(() => {
            props.productlist();
        }, [props]);
        
        useEffect(() => {
            console.log(new Date().toLocaleString(),'fetch:',products.isFetching);
            if(products.isFetching) {
                return setIsLoading(true) 
            }
            else if(!products.isFetching){
                return setIsLoading(false)
            }
        }, [products.isFetching]);

        return (
            <Container sx={{marginTop:'2em'}}>
                <Paper sx={{ p: 2, margin: 'auto', maxWidth: '100%', flexGrow: 1 }} elevation={0}>
                    <Grid container spacing={2}>
                        
                        <Grid item xs={6} md={8}>
                            <Typography component="div" variant="h5">
                                EXTRA MILE
                            </Typography>
                        </Grid>
                        
                        <Grid item xs={6} md={4}>
                             { isAdmin === true ?  <AdminRedeemFormButton/>  : null }
                        </Grid>
                        
                        <Grid item xs={12} md={12} sx={{marginTop:'25px'}}>
                            <p className="pdct-info" >
                            Welcome to UDOT's Extra Mile Catalog. Use your earned points to order an item of appreciation. Ordering will be done using the information you enter here, so make sure to indicate which size and colors you would like, on select items. Any editing or canceling of your order needs to be done before the order date listed on the home tab. If items are sold out or no longer available at the time of the order you will be contacted. Congratulations on being an outstanding employee, UDOT values you and the work of all of its employees.
                            </p>
                        </Grid>
                        
                        <Grid item xs={12} md={12} sx={{marginTop:'25px'}}>
                            <TextField 
                                id="margin-normal" 
                                margin="normal"  
                                InputProps={{ endAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)}}
                                type="search"
                                name="search-form"
                                placeholder="Search for..."
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </Grid>
                        <br/>
                        
                        { isLoading ? <div>Loading ...</div> : 
                            <div>
                                { _.has(products.productlist, 'data') ?
                                <Grid container spacing={2} sx={{padding: '50px 0px 0 35px'}}>
                                    { products.productlist.data.filter((val)=>{
                                        if(val.is_limited_qty && val.limited_qty <= 0) {
                                            return false;
                                        }
                                        if(q === "") {
                                        return val.product_name
                                        } else if (q !== "") {
                                        if(val.product_name.toLowerCase().includes(q.toLowerCase())){
                                            return val
                                        }
                                    } 
                                    }).map(product => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} key={product.product_id}>
                                            <ProductCard product={product} image={product.picture_url} title={product.product_name} description={product.description} points={product.pts_value} quantity={products.quantity} limited={products.limited_qty}/>
                                        </Grid>
                                    ))}
                                </Grid>
                                :  'Cant load products. Refresh the page.' }
                                </div>
                                }                        
                    </Grid>
                </Paper>
            </Container>
        )
}
