import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './reducers/root_reducer';
import thunk from 'redux-thunk';


// export default function configureStore(initialState){
//     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//     const store = createStore (
//         rootReducer, 
//         initialState, 
//         composeEnhancers(applyMiddleware(thunk))
//     )
//     return store;
// }

import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'


const persistConfig = { key: 'root', storage:storageSession }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer)
export default (initialState) => {
  let store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
}
