import React, { Component } from 'react'
import { Switch, Route } from "react-router-dom"
import CssBaseline from '@mui/material/CssBaseline';
import './App.scss';

import PrivateRoute from './containers/Auth/privateroute'
import PublicRoute  from './containers/Auth/publicroute'

import UserAccount from "./views/user";
import UserTransactions from './views/usertxns';
import Redeem from "./views/redeem";
import SignIn from "./views/signin";
import Footer from "./components/footer";
import NotFoundView from './views/404';


export default class App extends Component {
  constructor(){
    super();
    this.state = {
      
    }
  }
  render() {
    return (
      <div className="App">
        <CssBaseline />
        <div className="App-Header"></div>
        <Switch>
          <PublicRoute restricted={false} component={SignIn} path="/" exact/>
          <PrivateRoute component={UserAccount} path="/useraccount" exact />
          <PrivateRoute component={UserTransactions} path="/usertransactions" exact />
          <PrivateRoute component={Redeem} path="/redeem" exact />
          <Route component={NotFoundView} />
        </Switch>
        <br/>
        <Footer />
      </div>
    )
  }
}




