const convertISODateToYYYY = (date) => {
    let dateArr = date.split('T')
    return dateArr[0]
}

const convertISODateToDateTime = (date) => {
    let dateArr = date.split('T')
    return `${dateArr[0]} ${dateArr[1].replace('Z','').substring(0,8)}`
}


module.exports = {
    convertISODateToYYYY,
    convertISODateToDateTime
}