import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AdminGiveFormContainer from '../Forms/admingiveform'

const AdminGiveFormButton = () => {
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <React.Fragment>
            <Button variant="contained" color="primary"  onClick={handleClickOpen}>ADMIN GIVE FORM</Button><br/>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Employee Appreciation</DialogTitle>
                <DialogContent>
                <DialogContentText>
                Admin - Give Points 
                <br/>
                <br/>
                This form is used by Admins to give points. Any points given using this form will show as the colleague you are giving from.
                </DialogContentText>
                <br/>
                <AdminGiveFormContainer close={handleClose}/>
                </DialogContent>
            </Dialog>


        </React.Fragment>
    )
}

export default AdminGiveFormButton