import { USRORGNUM_REQUEST , USRORGNUM_RESPONSE } from '../actions/index';

export default function orgnumber(state = {
    isFetching: false,
    orgNumber: 0
  }, action) {
        switch (action.type) {
            case USRORGNUM_REQUEST:
                return {...state, isFetching: true, error: null }
            case USRORGNUM_RESPONSE:
                return {...state, isFetching: false, orgNumber: action.payload.data}
            default:
                return state
        }
  }